@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$color-primary: #00A69C;
$color-secondary: #F05A28;
$main-spacing: 120px;
$section_spacing:60px;
$border-gray:rgb(234, 234, 234);
$countdown_color:#762877;
$headings:clamp(2.2rem, 3.5vw, 3.5rem);

html {
    scroll-behavior: smooth;
}

body {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
    line-height: 1.7;
    font-size: 1.1rem;

    section {

        .container {
            width: 70%;
            margin: 0 auto;
        }

        //Banner Styling begins here...
        .banner_parent {
            .banner {
                width: 100%;
                height: 100svh;
                background: url(../images/background.webp);
                background-size: contain;
                background-position: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                container-type: size;
                container-name: banner;
                

                .banner_content {
                    width: max(400px, 60%);
                    height: max(200px,40vh);
                    
                    background-color: white;
                    padding: 55px 5px;
                    border-radius: 30px;
                    box-shadow: 0px 0px 10px rgb(177, 177, 177);
                    display: flex;
                    place-items: center;

                    .content {
                        width: 50%;
                        margin: auto;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        border-right: solid 1px rgb(234, 234, 234);

                        .logo {
                            width: max(300px, 70%);

                            img {
                                width: 100%;
                            }
                        }

                        h1 {
                            font-size: clamp(.8rem, 3vw, 4rem);
                            width: 300px;
                            font-weight: bold;
                            color: $color-secondary;
                            margin: 0;
                            line-height: 1.2;
                        }

                        h3 {
                            font-size: clamp(.8rem, 2vw, 2rem);
                            margin-bottom: 0;
                            color: darkslategrey;
                            font-weight: 300;
                        }

                        h4 {
                            margin: 5px 0px;
                            font-weight: 300;
                            font-size: clamp(1rem, 1vw, 1.5rem);
                            color: darkslategrey;
                            margin-bottom: max(20px, 3%);
                            //color: rgb(86, 86, 86);
                        }

                        p {
                            font-size: clamp(.8rem, .8rem, 1rem);
                        }

                        a{
                            width: 100%;
                            cursor: pointer;
                            button {
                                width: 100%;
                                padding: 20px 0;
                                font-size: clamp(1rem, 1.2vw, 1.6rem);
                                background-color: $color-secondary;
                                color: white;
                                border: none;
                                border-radius: 15px;
                                margin-top: 20px;
                                cursor: pointer;
                                font-weight: bold;

                                &:hover{
                                    background-color: #f03d01;
                                }
                            }
                        }

                        
                    }

                    .content_two{
                        width: 50%;
                        h3{
                            text-align: center;
                        }

                        h3:nth-child(1){
                            font-weight: 400;
                            font-size: clamp(.8rem,1.25vw,2rem);
                            text-align: center;
                            margin: 0;
                        }

                        h3:nth-child(2){
                            color: $color-secondary;
                            font-size: clamp(.7rem,1.3vw,2rem);
                        }

                        .heading{
                            display: grid;
                            place-items: center;
                            h3{
                                width: 65%;
                            }
                        }

                        .button_container{
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            a{
                                position: relative;
                                width: 100%;
                                display: grid;
                                place-items: center;
                                text-decoration: none;
                                margin: 10px 0;

                                button{
                                    width: 60%;
                                    background-color: transparent;
                                    padding: 20px 0;
                                    border: 1px solid $color-secondary;
                                    border-radius: 40px;
                                    font-size: clamp(.6rem,1.2vw,1.4rem);
                                    font-weight: bold;
                                    color: $color-secondary;
                                    cursor: pointer;
    
                                    &:nth-child(2){
                                        margin-top: 10px;
                                    }
    
                                    &:hover{
                                        background-color: $color-secondary;
                                        color: white;
                                    }
                                }
                            }
                            
                        }

                    }
                }

                a{
                   position: absolute;
                   bottom: 5%;
                }

                .down_arrow {
                    background-color: $color-secondary;
                    padding: 10px;
                    border-radius: 50%;
                    margin-top: 30px;
                    width: max(40px,70%);
                    height: max(40px,3vh);
                    display: grid;
                    place-items: center;
                    cursor: pointer;

                    img {
                        width: 70%;
                    }
                }
            }

        }

        //Coumtdown styling begins here...
        .count_down_container{
            margin-top: 120px;
            .container{
                text-align: center;
                width: 60%;

                .heading{
                    h2{
                        font-weight: 400;
                        font-size: clamp(1rem,1.7vw,2rem);
                    }
                   
                }

                .countdown{
                   
                    .flexbox{
                        display: flex;
                        justify-content: space-evenly;

                        h1{
                            font-size: clamp(2.5rem,5vw,5.5rem);
                            margin-bottom: 0;
                            line-height: 1;
                            color: $countdown_color;
                        }

                        p{
                            margin-top: 0;
                            color: $color-primary;
                        }
                    }
                }
            }
        }

        //Programe outline styling begins here...
        .program_parent {
            margin-top: $main-spacing;
            background: url(../images/pattern_1.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: right;

            .container {
                width: 70%;
                margin: auto;
                
                .heading{
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    margin: 10px;
                    // border: red 1px solid;
                    h1 {
                        
                        text-align: center;
                        font-size: $headings;
                        font-weight: bold;
                        color: $color-secondary;
                        margin: 0 20px;
                        line-height: .7;
                        // height: 67px;
                    }

                    img{
                        width: 3.7%;
                    }
                }

                

                .program_container {
                    border: 3px solid $color-primary;
                    border-radius: 30px;
                    overflow: hidden;
                    container: program/inline-size;
                    margin-top: 30px;

                    .header {
                        background-color: $color-primary;
                        color: white;
                        font-size: clamp(1rem, 2vw, 2rem);
                        text-align: center;
                    }

                    .program_body {
                        padding: 50px ;
                        background-color: rgba(255, 255, 255, 0.811);
                        

                        .program_calender {
                            .program_flexbox {
                                display: flex;
                                gap: 10px;

                                .flex {
                                    padding: 10px 0;
                                    

                                    .mobile_time{
                                       display: none;
                                        color: $color-secondary;
                                    }

                                    p {
                                        margin: 0;
                                        line-height: 1.5;
                                    }

                                    .placeholder {
                                        opacity: 0;
                                    }

                                    .dates {
                                        color: $color-secondary;
                                    }

                                    &:nth-child(1) {
                                        color: $color-secondary;
                                        width: max(100px,15%);
                                        
                                        p{
                                            font-size: clamp(.8rem,1vw,1rem);
                                        }
                                    }

                                    &:nth-child(2) {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;

                                        .circle {
                                            width: 15px;
                                            height: 15px;
                                            background-color: $color-secondary;
                                            border-radius: 50%;
                                            margin-top: 4px;
                                        }

                                        .line {
                                            width: 1px;
                                            height: 80px;
                                            border-left: 1px solid $color-secondary;
                                            margin-top: 10px;
                                        }
                                    }

                                    &:nth-child(3) {
                                        flex-grow: 1;
                                    }

                                    &:nth-child(4) {
                                        font-size: clamp(.9rem, 2rem, 2rem);
                                        display: grid;
                                        place-items: center;
                                        color: $color-secondary;
                                        cursor: pointer;
                                    }
                                }

                                .flex:nth-child(3){
                                    p{
                                        font-weight: 300;
                                    }

                                    p:nth-child(2){
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }

        //Accomodation styling starts here...
        .accomodation_parent {
            margin-top: $section_spacing;
            background-image: url(../images/pattern_2.png);
            background-repeat: no-repeat;
            background-size: 30%;
            background-position: left center;


            .container {
                width: 70%;
                margin: 0 auto;

                .header {
                    text-align: center;
                    color: $color-secondary;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;

                    .acc_img{
                        width: 6.1%;
                        display: grid;
                        place-items: end;
                        img{
                            width: 100%;
                        }
                    }
                    

                    h1 {
                        font-weight: bold;
                        font-size: $headings;
                        margin:0 20px;
                        line-height: .7;
                    }

                    
                }

                .sub_header{
                    h2 {
                        color: $color-primary;
                        font-weight: bold;
                        font-size: clamp(1rem,2vw,2.5rem);
                    }
                }

                .flexbox {
                    display: flex;

                    .flex {
                        width: 50%;

                        position: relative;

                        .content{
                            p:nth-child(2){
                                span{
                                    color: $color-secondary;
                                    font-weight: bold;
                                }
                            }
                        }

                        &:nth-child(2) {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            img {
                                width: 50%;
                                position: absolute;
                            }
                        }


                        .location {
                            display: flex;
                            align-items: center;

                            .icon {
                                width: 20px;


                                img {
                                    width: 100%;

                                }
                            }

                            p {
                                margin-left: 10px;
                            }
                        }



                    }
                }

                .button_holder{
                    button {
                        background-color: $color-secondary;
                        border: none;
                        padding: 20px 30px;
                        color: white;
                        font-size: clamp(1rem, 1.3vw, 1.5rem);
                        border-radius: 30px;
                        margin-top: 30px;
                        font-weight: bold;
                        cursor: pointer;
                        
                    }
                }

                //Flights information starts here...

                .flights_parent {
                    margin-top: $section_spacing;

                    .flight_header{
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;

                        .flight_icon{
                            width: 3.8%;
                            display: grid;
                            place-items: center;

                            img{
                                width: 100%;
                            }
                        }

                        h1{
                         font-size: $headings;
                         color: $color-secondary;
                         margin: 0 20px;
                         line-height: .7;   
                        }
                    }

                    .flight_content {
                        color: black;
                        text-align: left;

                        p:nth-child(1){
                            margin: 40px 0 30px 0;
                        }

                        .flight_details{
                            border: 1px solid $color-primary;
                            border-radius: 30px;
                            padding: 40px 0;

                            .flights{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin-top: 20px;

                                .line{
                                    width: 10%;
                                    border-top: solid 1px rgb(176, 176, 176);
                                    margin: 0 20px;
                                }
                            }
                        }

                        h2 {
                            font-weight: 500;
                            color: $color-primary;
                            font-size: clamp(1rem,2vw,2.5rem);
                            text-align: center;
                            margin: 0;
                        }

                        .flight_flexbox {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            div{
                                width: 8%;

                                img{
                                    width: 100%;
                                }
                            }

                            p {
                                margin: 0;
                                margin-left: 30px;
                            }
                        }
                    }

                }
            }
        }

        //Travel Requirements styling begins here...
        .visa_parent {
            margin-top: $section_spacing;
            background-image: url(../images/pattern_3.png);
            background-size: contain;
            background-position: right;
            background-repeat: no-repeat;

            .container {
                .header {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    container-type: inline-size;
                    container-name: visa;

                    .visa_icon{
                        width: 2.7%;
                        display: flex;
                        align-items: flex-end;

                        img{
                            width: 100%;
                        }
                    }

                    h1 {
                        font-weight: bold;
                        font-size: $headings;
                        color: $color-secondary;
                        margin: 0 20px;
                        line-height: .7;
                    }
                }

                .button_holder {
                    padding: 30px 0;
                    border-bottom: solid 1px $color-primary;

                    button {
                        border: none;
                        border: 1px solid $color-primary;
                        color: $color-primary;
                        padding: 20px 40px;
                        background-color: white;
                        border-radius: 30px;
                        font-size: clamp(1rem, 1.2vw, 1.2rem);
                        transition: .3s;
                        cursor: pointer;

                        &:nth-child(2) {
                            margin-left: 30px;
                        }

                        &:hover{
                            background-color: $color-primary;
                            color: white;
                        }
                    }


                }

                .details {

                    h2{
                        margin-top: 40px;
                        color: $color-secondary;
                    }
                    b{
                        color: $color-secondary;
                    }

                    p {
                        &:nth-child(1){
                            font-weight: bold;
                        }
                       :is(&:nth-child(5),
                       &:nth-child(10),
                       &:nth-child(11)){
                            color: $color-primary;
                            display: flex;
                            align-items: center;
                            gap: 10px;

                           img{
                            width: 2%;
                           }

                            
                            a{
                                color: $color-primary;
                            }
                        }
                    }

                    ol {
                        line-height: 2;
                        padding-left: 15px;

                        li{
                            //text-indent: 30px; 
                            padding-left: 30px;
                        }
                    }

                    span{
                        font-weight: bold;
                        color: $color-secondary;
                    }
                }
            }
        }

        //Weather styling begins here..
        :is(
            .weather_parent,
            .what-to-pack_parent
            
            ){
            margin-top: $section_spacing;
            .container{
                .heading{
                    display: flex;
                        align-items: flex-end;
                        justify-content: center;
                        gap: 15px;

                    .weather_icon{
                        width: 4.5%;
                        display: grid;
                        place-items: center;
                        img{
                            width: 100%;
                        }
                    }

                    .pack_icon{
                        width: 2.3%;
                        display: grid;
                        place-items: center;
                        img{
                            width: 100%;
                        }
                    }
                    
                    h1{
                        margin: 0;
                        font-size: $headings;
                        color: $color-secondary;
                        line-height: .7;
                    }
                }
            }
        }

        
       


        //Speakers styling begins here...
        .speakers_parent {
            margin-top: $main-spacing;
            

            .container {
                .header {
                    text-align: center;

                    h1 {
                        font-weight: bold;
                        font-size: clamp(1rem, 2.5vw, 2.5rem);
                        color: $color-secondary;
                    }
                }

                .flexbox {
                    display: flex;
                    justify-content: space-between;
                    gap: 20px;

                    .speaker {
                        text-align: center;
                        width: 25%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        //padding: 10px;

                        .speaker_img {
                            width: 100%;
                            aspect-ratio: auto 1/1;
                            border: 2px solid $color-primary;
                            border-radius: 20px;
                            background-image: url(https://img.freepik.com/free-photo/portrait-young-man-isolated_23-2149158608.jpg?t=st=1716281778~exp=1716285378~hmac=6d818f18bc8cefeeaeff4b91e8d6eed21846401534476e5ef7cb50e7f898fcc1&w=740);
                            background-size: cover;
                        }

                        .details {
                            margin: 20px 0;

                            h3,
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        //Footer styling begins here...
        footer {
            margin-top: $main-spacing;

            .complete_registration {
                width: 100%;
                //height: 200px;
                border-top: solid 1px $color-secondary;
                padding: 60px 0;
               

                .container{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;

                    h1{
                        margin: 0;
                        font-size: clamp(1.5rem,1.8vw,1.8rem);
                        color: $color-secondary;
                    }
                    button {
                        background-color: transparent;
                        color: $color-secondary;
                        font-size: clamp(.9rem, 1.3vw, 1.5rem);
                        font-weight: bold;
                        border: solid 1px $color-secondary;
                        border-radius: 40px;
                        padding: 25px 40px;
                        cursor: pointer;
    
                        &:hover{
                            background-color: $color-secondary;
                            color: white;
                        }
                    }
                }

                
            }

            .inquiries_section{
                background-color: $color-secondary;
                text-align: center;

                h1{
                    margin: 0;
                    color: white;
                    padding: 20px 0;

                    a{
                        text-decoration: none;
                        color:white;
                    }
                }
            }

            .footer_parent {
                background-color: $color-primary;
                padding: 80px 0;

                .container {
                    .flexbox {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        color: white;

                        .flex {
                            .logo {

                                background-color: white;
                                position: relative;
                                border-radius: 50%;
                                width: 150px;
                                height: 150px;

                                display: grid;
                                place-items: center;

                                img {
                                    width: 60%;
                                    position: absolute;


                                }

                            }

                            &:nth-child(2){
                                p{
                                    width: 60%;
                                    font-size: .9rem;
                                }
                            }

                            &:nth-child(3){
                                width: 70%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                .socials{
                                   
                                    ul{
                                        list-style-type: none;
                                        padding-left: 0;
                                        display: inline-flex;
                                        gap: 15px;
                                        justify-content: center;
                                        margin: 0;

                                        a{

                                            
                                                
                                                width: 25%;
                                                aspect-ratio: auto 1/1;
                                            li{
                                          
                                                
    
                                                img{
                                                    width: 100%;
                                                }
        
                                                &:nth-child(1){
                                                    padding-left: 0;
                                                }
                                            }
                                        }
                                       
                                    }
                                }

                                
                            }

                        }
                    }
                }

                
            }

            .footer_pattern{
                width: 100%;
                height: 50px;
                background-image: url(../images/background.png);
            }
        }
    }
}

